import { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  MenuItem,
  useTheme,
  useMediaQuery,
  Menu,
  Avatar,
  Grid,
  Stack,
  Chip,
  Badge,
  Tooltip,
} from "@mui/material";
import { DarkMode, LightMode } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
// import SideNav from "sidenav/SideNav";
import DrawerWithHeader from "components/drawer";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import APPCONSTANTS from "configs/constants";
import StudentFilter from "./StudentFilter";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  setHistoryData,
  setPhaseHistoryData,
  setSubjectHistory,
  setSubjectIds,
} from "state";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const fullName = `${user.name}`;
  const userImage = `${user.image}`;
  const empNo = `${user.employeeNo}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutClick = () => {
    setAnchorEl(null);
    dispatch(
      setHistoryData({
        hod_id: "",
      })
    );

    dispatch(
      setSubjectHistory({
        subject_id: "",
      })
    );

    dispatch(
      setPhaseHistoryData({
        Hphases: null,
        Hclasses: null,
      })
    );

    dispatch(
      setSubjectIds({
        subjectIds: [],
        kgPhases: "",
      })
    );
    localStorage.removeItem("authUser");
    dispatch(setLogout());
    navigate("/");
  };
  const handleImageError = (event) => {
    event.target.src = "/assets/dummy-image.jpg"; // Replace with the path to your fallback image
  };
  return (
    <>
      <FlexBetween
        padding={2}
        backgroundColor={alt}
        sx={{ marginTop: isNonMobileScreens ? "5px" : "" }}
      >
        <FlexBetween>
          {!isNonMobileScreens && <DrawerWithHeader />}
          <Typography
            variant={isNonMobileScreens ? "h2" : "h3"}
            fontWeight="bold"
            color="#3699FF"
            onClick={() => navigate("/home")}
            sx={{
              "&:hover": {
                color: "#3699FF",
                cursor: "pointer",
              },
              marginLeft: isNonMobileScreens ? "25px" : "20px",
            }}
          >
            TEACHER EVALUATION
          </Typography>
          {isNonMobileScreens && (
            <Box
              sx={{
                marginLeft: { md: "100px", lg: "200px" },
                borderRadius: "9px",
              }}
            >
              <StudentFilter></StudentFilter>
            </Box>
          )}
        </FlexBetween>

        {/* DESKTOP NAV */}
        {isNonMobileScreens ? (
          <Grid
            container
            spacing={0}
            justifyContent="flex"
            sx={{ width: { lg: "19%", md: "26%" } }}
          >
            <FlexBetween width={"70%"} gap={2}>
              {/* <Tooltip title={"Notification"}>
                <Badge badgeContent={4} color="primary">
                  <NotificationsIcon color="action" />
                </Badge>
              </Tooltip> */}

              <Tooltip
                title={
                  theme.palette.mode === "dark"
                    ? "Switch to light mode"
                    : "Switch to dark mode"
                }
              >
                <IconButton onClick={() => dispatch(setMode())}>
                  {theme.palette.mode === "dark" ? (
                    <DarkMode sx={{ fontSize: "25px" }} />
                  ) : (
                    <LightMode sx={{ color: dark, fontSize: "25px" }} />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip title={fullName}>
                <Chip
                  sx={{
                    height: "2.3rem",
                    width: "fit-content",
                    p: 1,
                    ml: "auto",
                  }}
                  avatar={
                    <Avatar
                      sx={{ transform: "translate(-5px,0px)" }}
                      width={25}
                      height={25}
                      src={APPCONSTANTS.FILE_SERVER_URL + userImage}
                      onError={handleImageError}
                    />
                  }
                  label={
                    <>
                      <Typography fontSize={12}>
                        {fullName.length > 10
                          ? `${fullName.substring(0, 10)}...`
                          : fullName}
                      </Typography>
                      <Typography fontSize={10}>{empNo}</Typography>
                    </>
                  }
                  variant="outlined"
                  color="default"
                  onClick={handleClick}
                />
              </Tooltip>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{ top: "35px" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logoutClick}>
                  <PowerSettingsNewIcon /> Logout
                </MenuItem>
              </Menu>
            </FlexBetween>
          </Grid>
        ) : (
          <IconButton
            onClick={(event) => setIsMobileMenuToggled(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
        )}

        {/* MOBILE NAV */}
        {!isNonMobileScreens && isMobileMenuToggled && (
          <Menu
            anchorEl={isMobileMenuToggled}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(isMobileMenuToggled)}
            onClose={() => {
              setIsMobileMenuToggled(false);
            }}
            // className="chumma"
            // position="fixed"
            // right="0"
            // bottom="0"
            // height="100%"
            // zIndex="10"
            // maxWidth="90px"
            // minWidth="50px"
            // backgroundColor={background}
          >
            {/* CLOSE ICON */}
            {/* <Box display="flex" justifyContent="flex-end" p="1rem">
              <IconButton
                onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
              >
                <Close />
              </IconButton>
            </Box> */}

            {/* MENU ITEMS */}
            <FlexBetween
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap=".5rem"
              p={2}
            >
              <IconButton
                onClick={() => dispatch(setMode())}
                sx={{ fontSize: "25px" }}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkMode sx={{ fontSize: "30px" }} />
                ) : (
                  <LightMode sx={{ color: dark, fontSize: "30px" }} />
                )}
              </IconButton>
              <Avatar
                width={25}
                height={25}
                src={APPCONSTANTS.FILE_SERVER_URL + userImage}
                onError={handleImageError}
              />
              <IconButton onClick={() => logoutClick()}>
                <LogoutIcon sx={{ fontSize: "30px" }} title="Logout" />
              </IconButton>
            </FlexBetween>
          </Menu>
        )}
      </FlexBetween>
      {!isNonMobileScreens && (
        <Stack
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: "20px" }}
        >
          <StudentFilter></StudentFilter>
        </Stack>
      )}
    </>
  );
};

export default Navbar;
